<template>
  <div class="edit-promocode-page">
    <BackTitle confirm-click @click="goBack"> Edit promocode </BackTitle>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <Guard
      v-else-if="promocode"
      permission="promocodes.edit"
      v-slot="{ isAvailable }"
    >
      <PromocodeForm
        :value="promocode"
        :is-submitting="isSubmitting"
        has-delete-button
        :is-disabled="!isAvailable"
        @submit="save"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import Guard from "@/components/common/Guard.vue";
import PromocodeForm from "@/components/promocodes/PromocodeForm.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "EditPromocodePage",
  components: { PromocodeForm, Guard, BackTitle },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      promocode: (state) => state.promocodes.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchPromocodeById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      updatePromocode: "promocodes/updatePromocode",
      fetchPromocodeById: "promocodes/fetchPromocodeById",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updatePromocode({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Promocodes",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.edit-promocode-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
